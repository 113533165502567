<template>
  <div id="productionEntry">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px;">单据日期</div>
        <DatePicker
          type="daterange"
          placeholder="请选择"
          style="width: 220px"
          v-model="listData.params.dateRange"
          @keyup.enter.native="changeDate"
        ></DatePicker>
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px;">单据编号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.docNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:26px;">状态</div>
        <Select
          slot="list"
          style="width: 210px"
          v-model="listData.params.status"
          @on-change="getTableDataList"
        >
          <Option v-for="item in statusList" :key="item" :value="item">{{
            item
          }}</Option>
        </Select>
      </div>
      <div class="filterItem">
        <div style="min-width:56px;;margin-right:10px">
          单据类型
        </div>
        <Select
          slot="list"
          style="width: 210px"
          v-model="listData.params.docType"
          @on-change="getTableDataList"
        >
          <Option v-for="item in typeList" :key="item" :value="item">{{
            item
          }}</Option>
        </Select>
      </div>
      <div class="filterItem">
        <div style="min-width:26px;margin-right:10px">货号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.orderNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <Button @click="customFilter"
          >自定义筛选
          <i v-if="!customShow" class="iconfont iconicon-xia"></i>
          <i v-if="customShow" class="iconfont iconicon-shang"></i>
        </Button>
      </div>
    </div>
    <!-- 自定义筛选选择 -->
    <div class="custom" v-if="customShow">
      <!-- <div class="triangle"></div> -->
      <div class="check" v-if="customSetting">
        <Checkbox
          :indeterminate="indeterminate"
          :value="checkAll"
          @click.prevent.native="handleCheckAll"
          >全选</Checkbox
        >
        <div class="border"></div>
        <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
          <Checkbox
            v-for="item in checkList"
            :key="item.index"
            :label="item.label"
          ></Checkbox>
        </CheckboxGroup>
        <div class="btn">
          <!-- <Button @click="cancelSetting">取消</Button> -->
          <Button type="primary" @click="confirmSetting">确定</Button>
        </div>
      </div>
      <!-- 自定义筛选确认后 -->
      <div class="searchSetting" v-if="alreadySetting">
        <div class="settingFilter">
          <div
            class="filterItem"
            v-for="item in selectedCheckList"
            :key="item.key"
          >
            <div style="margin-right:10px">{{ item.label }}</div>
            <Input
              placeholder="请输入搜索内容"
              style="width: 212px"
              v-model.trim="listData.params[item.key]"
              @keyup.enter.native="getTableDataList"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="refresh('main')">
            <i class="iconfont iconicon-shuaxin"></i>
            刷新
          </div>
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu"></i>
            同步
          </div>
          <div class="item" @click="showTableTitleDialog">
            <i class="iconfont iconicon-chuansuokuang"></i>
            表格设置
          </div>
          <div class="item"></div>
          <div class="item" @click="exportProductionEntryList">
            <i class="iconfont iconicon-daochu"></i>
            导出
          </div>
          <div class="item" @click="getTableDataList">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <Table
      :columns="tableRealColumns"
      :data="tableData"
      border
      show-summary
      :summary-method="handleSummary"
      :loading="loading"
    ></Table>

    <!-- 分页 -->
    <Page
      class-name="page"
      :total="listDataTotal"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
    <!-- 下方的抽屉弹窗 -->
    <el-drawer
      title="进仓明细表"
      size="570px"
      :visible.sync="drawerShow"
      direction="btt"
    >
      <div class="button">
        <div class="buttonRow">
          <div class="left">
            <div class="item" @click="refresh('detail')">
              <i class="iconfont iconicon-shuaxin"></i>
              刷新
            </div>
            <div class="item" @click="showTableTitleDialogDetail">
              <i class="iconfont iconicon-chuansuokuang"></i>
              表格设置
            </div>
          </div>
        </div>
      </div>
      <Table
        :columns="detailsTableRealColumns"
        :data="detailsTableData"
        border
        show-summary
        :summary-method="handleSummaryDetail"
        :loading="loadingDetail"
      ></Table>
      <Page
        class-name="page"
        :total="detailsListDataTotal"
        show-elevator
        show-sizer
        show-total
        :page-size="detailsListData.pageSize"
        :current="detailsListData.pageNum"
        @on-page-size-change="detailsPageSizeChange"
        @on-change="detailsPageNumberChange"
      />
    </el-drawer>
    <!-- 穿梭框的弹出框 -->
    <TableSetting
      :routerPath="routerPath"
      :theadDateReal="bindTheadDateReal"
      @tableDataList="bindTableDataList"
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
    ></TableSetting>
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";
export default {
  components: {
    TableSetting
  },
  data() {
    return {
      loading: true,
      loadingDetail: true,
      routerPath: "",
      bindTheadDateReal: [],
      bindTableDataList: [],
      drawerShow: false, // 是否展示下方的抽屉弹窗
      checkList: [
        {
          index: 1,
          label: "布卷条码",
          key: "yardageRollCode"
        },
        {
          index: 2,
          label: "产品条码",
          key: "productionCode"
        },
        {
          index: 3,
          label: "客户款号",
          key: "clientNum"
        },
        {
          index: 4,
          label: "色号",
          key: "colorNum"
        }
      ],
      chuansuoDialogVisible: false,
      customShow: false,
      customSetting: true,
      alreadySetting: false,
      selectedCheck: [], // 已选中的可选条件组成的数组
      selectedCheckList: [], // 已选中的可选条件组成的数组list
      indeterminate: false, // 只负责样式控制,true为是
      checkAll: false, // 是否全选
      checkAllGroup: [], // 已选的多选框的存储
      typeList: [],
      statusList: [],
      listData: {
        params: {
          docType: "",
          docNumber: "",
          status: "",
          orderNumber: "",
          yardageRollCode: "",
          productionCode: "",
          clientNum: "",
          colorNum: "",
          dateRange: [],
          docDateStart: "",
          docDateEnd: ""
        },
        pageSize: 10,
        pageNum: 1
      },
      summary: {
        ztpeSettlementQuantityAll: "",
        ztpeTotalNumAll: "",
        ztpeClothQuantityAll: "",
        ztpeNetWeightTotalAll: "",
        ztpeGrossWeightTotalAll: "",
        ztpeMetersTotalAll: ""
      },
      listDataTotal: 0,
      tableRealColumns: [],
      tableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "操作",
          key: "action",
          width: 80,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    fontSize: "14px",
                    color: "#256DE6",
                    cursor: "pointer"
                  },
                  on: {
                    click: () => {
                      this.drawerShow = true;
                      this.currentId = params.row.ztpeId;
                      this.getTableDataList1();
                    }
                  }
                },
                "详情"
              )
            ]);
          }
        },
        {
          title: "状态",
          key: "ztpeStatus",
          minWidth: 80
        },
        {
          title: "单据编号",
          key: "ztpeDocNumber",
          minWidth: 120
        },
        {
          title: "货号", // 之前是关联单号
          key: "ztpeOrderNumber",
          minWidth: 120
        },
        {
          title: "担当",
          key: "ztpeTakeOn",
          minWidth: 100
        },
        {
          title: "已结算数量",
          key: "ztpeSettlementQuantity",
          minWidth: 120
        },
        {
          title: "操作日志",
          key: "ztpeOperationLog",
          minWidth: 10000
        },
        {
          title: "数量合计",
          key: "ztpeTotalNum",
          minWidth: 120
        },
        {
          title: "建档日",
          key: "ztpeCreateDate",
          minWidth: 200
        },
        {
          title: "关联单位编号",
          key: "ztpeUnitNumber",
          minWidth: 150
        },
        {
          title: "单据类型",
          key: "ztpeDocType",
          minWidth: 120
        },
        {
          title: "审核日",
          key: "ztpeExamineDate",
          minWidth: 200
        },
        {
          title: "匹数合计",
          key: "ztpeClothQuantity",
          minWidth: 120
        },
        {
          title: "净重合计",
          key: "ztpeNetWeightTotal",
          minWidth: 120
        },
        {
          title: "单据日期",
          key: "ztpeDocDate",
          minWidth: 200
        },
        {
          title: "建档者",
          key: "ztpeCreateUser",
          minWidth: 120
        },
        {
          title: "毛重合计",
          key: "ztpeGrossWeightTotal",
          minWidth: 120
        },
        {
          title: "米数合计",
          key: "ztpeMetersTotal",
          minWidth: 120
        },
        {
          title: "详细描述",
          key: "ztpeDetails",
          minWidth: 200
        },
        {
          title: "码数合计",
          key: "ztpeYardsTotal",
          minWidth: 120
        },
        {
          title: "关联单位名称",
          key: "ztpeUnitName",
          minWidth: 200
        },
        {
          title: "隶属部门",
          key: "ztpeDeptName",
          minWidth: 120
        },
        {
          title: "审核者",
          key: "ztpeExamineUser",
          minWidth: 120
        },
        {
          title: "编辑日",
          key: "ztpeEditDate",
          minWidth: 200
        },
        {
          title: "编辑者",
          key: "ztpeEditUser",
          minWidth: 120
        }
      ],
      tableData: [],
      currentId: "",
      detailsListData: {
        pageSize: 8,
        pageNum: 1
      },
      detailsTableRealColumns: [],
      detailsTableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.detailsListData.pageNum - 1) *
                  this.detailsListData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "布卷条码",
          key: "ztpeiYardageRollCode",
          minWidth: 150
        },
        {
          title: "米数",
          key: "ztpeiMeter",
          minWidth: 200
        },
        {
          title: "匹数",
          key: "ztpeiClothNum",
          minWidth: 100
        },
        {
          title: "净重",
          key: "ztpeiNetWeight",
          minWidth: 200
        },
        {
          title: "数量",
          key: "ztpeiNum",
          minWidth: 200
        },
        {
          title: "公司订单号",
          key: "ztpeiCompanyOrderNum",
          minWidth: 120
        },
        {
          title: "克重",
          key: "ztpeiGram",
          minWidth: 200
        },
        {
          title: "已结算数量",
          key: "ztpeiSettledNum",
          minWidth: 110
        },
        {
          title: "染整单号",
          key: "ztpeiDyeingOrderNum",
          minWidth: 120
        },
        {
          title: "客户订单",
          key: "ztpeiDyeingOrderNum",
          minWidth: 120
        },
        {
          title: "产品条码",
          key: "ztpeiProductionCode",
          minWidth: 120
        },
        {
          title: "门幅",
          key: "ztpeiLarghezza",
          minWidth: 100
        },
        {
          title: "包号",
          key: "ztpeiPackageNum",
          minWidth: 100
        },
        {
          title: "卷号",
          key: "ztpeiRollNum",
          minWidth: 100
        },
        {
          title: "存储位置",
          key: "ztpeiLocation",
          minWidth: 100
        },
        {
          title: "客户颜色",
          key: "ztpeiClientColor",
          minWidth: 120
        },
        {
          title: "码数",
          key: "ztpeiYard",
          minWidth: 100
        },
        {
          title: "毛重",
          key: "ztpeiRoughWeight",
          minWidth: 120
        },
        {
          title: "单位",
          key: "ztpeiUnit",
          minWidth: 100
        },
        {
          title: "送数",
          key: "ztpeiSendNum",
          minWidth: 100
        },
        {
          title: "货号",
          key: "orderNumber",
          minWidth: 100
        },
        {
          title: "品名",
          key: "productNumber",
          minWidth: 100
        },
        {
          title: "花型",
          key: "ztpeiDesignType",
          minWidth: 120
        },
        {
          title: "品检状态",
          key: "ztpeiQualityStatus",
          minWidth: 120
        },
        {
          title: "工序标记",
          key: "ztmoiDocType",
          minWidth: 120
        },
        {
          title: "备注",
          key: "ztpeiRemarks",
          minWidth: 120
        },
        {
          title: "等级",
          key: "ztpeiGrade",
          minWidth: 65
        },
        {
          title: "客户款号",
          key: "ztpeiClientNum",
          minWidth: 120
        },
        {
          title: "缸号",
          key: "ztpeiVatNum",
          minWidth: 120
        },
        {
          title: "中文颜色",
          key: "ztpeiChineseColor",
          minWidth: 100
        },
        {
          title: "色号",
          key: "ztpeiColorNum",
          minWidth: 120
        },
        {
          title: "面料性质",
          key: "ztpeiFabricNature",
          minWidth: 120
        },
        {
          title: "系统包号",
          key: "ztpeiSystemPackageNum",
          minWidth: 120
        },
        {
          title: "送重",
          key: "ztpeiSendWeight",
          minWidth: 100
        },
        {
          title: "区域",
          key: "ztpeiArea",
          minWidth: 100
        }
      ],
      summaryDetail: {
        ztpeiMeterAll: "",
        ztpeiClothNumAll: "",
        ztpeiNetWeightAll: "",
        ztpeiNumAll: "",
        ztpeiGramAll: "",
        ztpeiSettledNumAll: "",
        ztpeiRoughWeightAll: "",
        ztpeiSendNumAll: "",
        ztpeiSendWeightAll: ""
      },
      detailsTableData: [],
      detailsListDataTotal: 0
    };
  },
  created() {
    this.getDocTypeList();
    this.getStatusList();
    this.getTableDataList();
  },
  methods: {
    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.getTableDataList();
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.getTableDataList();
    },
    detailsPageSizeChange(pageSize) {
      this.detailsListData.pageSize = pageSize;
      this.detailsListData.pageNum = 1;
      this.getTableDataList1();
    },
    detailsPageNumberChange(pageNum) {
      this.detailsListData.pageNum = pageNum;
      this.getTableDataList1();
    },
    // 主表显示弹出框
    showTableTitleDialog() {
      this.routerPath =
        "/dtsum/zongtong/product/productController/productionEntrySheetSearch";
      this.bindTheadDateReal = this.tableRealColumns;
      this.bindTableDataList = this.getTableDataList;
      this.chuansuoDialogVisible = true;
    },
    // 细表显示弹出框
    showTableTitleDialogDetail() {
      this.routerPath =
        "/dtsum/zongtong/product/productController/productionEntrySheetDetail";
      this.bindTheadDateReal = this.detailsTableRealColumns;
      this.bindTableDataList = this.getTableDataList1;
      this.chuansuoDialogVisible = true;
    },
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;
      if (this.checkAll) {
        this.checkAllGroup = this.checkList.map(function(item) {
          return item.label;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.checkList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    // 点击自定义筛选
    customFilter() {
      if (this.selectedCheck.length > 0) {
        this.customShow = true;
        if (this.alreadySetting) {
          this.customSetting = true;
          this.alreadySetting = false;
          this.checkAllGroup = this.selectedCheck;
          if (this.checkList.length !== this.checkAllGroup.length) {
            this.indeterminate = true;
          } else {
            this.indeterminate = false;
            this.checkAll = true;
          }
        } else {
          this.customSetting = false;
          this.alreadySetting = true;
        }
      } else {
        this.customShow = !this.customShow;
        this.checkAllGroup = this.selectedCheck;
        this.indeterminate = false;
      }
    },
    // 确定自定义筛选
    confirmSetting() {
      this.selectedCheck = this.checkAllGroup;
      if (this.selectedCheck.length > 0) {
        this.customSetting = false;
        this.alreadySetting = true;
        this.customShow = true;
        var arr = [];
        this.selectedCheck.forEach((item) => {
          this.checkList.forEach((item1) => {
            if (item === item1.label) {
              arr.push(item1);
            }
          });
        });
        this.selectedCheckList = arr;
      } else {
        this.customSetting = true;
        this.alreadySetting = false;
        this.customShow = false;
        this.indeterminate = false;
      }
    },
    // 获取单据类型接口
    getDocTypeList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "productentertype"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.typeList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取状态接口
    getStatusList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "productenterstatus"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.statusList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 刷新表格
    refresh(params) {
      if (params === "main") {
        this.loading = true;
        this.getTableDataList();
      } else {
        this.loadingDetail = true;
        this.getTableDataList1();
      }
    },
    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableDataList();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 导出
    exportProductionEntryList() {
      const data = this.listData.params;
      window.location.href = `${this.baseUrl}/dtsum/zongtong/product/productController/exportProductionEntrySheet?docType=${data.docType}&docDateStart=${data.docDateStart}&docDateEnd=${data.docDateEnd}&docNumber=${data.docNumber}&status=${data.status}&orderNumber=${data.orderNumber}&yardageRollCode=${data.yardageRollCode}&productionCode=${data.productionCode}&clientNum=${data.clientNum}&colorNum=${data.colorNum}`;
    },
    // 日期改变进行拆分
    changeDate() {
      const date = this.listData.params.dateRange;
      if (date[0] !== "" && date[0] !== null) {
        this.listData.params.docDateStart = this.moment(date[0]).format(
          "YYYY-MM-DD"
        );
        this.listData.params.docDateEnd = this.moment(date[1]).format(
          "YYYY-MM-DD"
        );
      } else {
        this.listData.params.docDateStart = "";
        this.listData.params.docDateEnd = "";
      }
      this.getTableDataList();
    },
    // 成品进仓主表
    getTableDataList() {
      this.loading = true;
      this.tableRealColumns.length = 0;
      this.axios({
        url:
          "/dtsum/zongtong/product/productController/productionEntrySheetSearch",
        method: "get",
        params: this.listData
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (this.tableRealColumns.length === 0) {
              this.tableRealColumns.push(this.tableColumns[0]);
              this.tableRealColumns.push(this.tableColumns[1]);
              for (let i = 0; i < res.data.body.userFilds.length; i++) {
                for (let j = 0; j < this.tableColumns.length; j++) {
                  if (
                    res.data.body.userFilds[i].field ===
                    this.tableColumns[j].key
                  ) {
                    this.tableRealColumns.push(this.tableColumns[j]);
                  }
                }
              }
              this.tableData = res.data.body.productionEntrySheetList.list;
              this.listDataTotal = res.data.body.productionEntrySheetList.total;
              this.listData.pageSize =
                res.data.body.productionEntrySheetList.pageSize;
              this.listData.pageNum =
                res.data.body.productionEntrySheetList.pageNum;
              this.summary.ztpeSettlementQuantityAll =
                res.data.body.ztpeSettlementQuantityAll;
              this.summary.ztpeTotalNumAll = res.data.body.ztpeTotalNumAll;
              this.summary.ztpeClothQuantityAll =
                res.data.body.ztpeClothQuantityAll;
              this.summary.ztpeNetWeightTotalAll =
                res.data.body.ztpeNetWeightTotalAll;
              this.summary.ztpeGrossWeightTotalAll =
                res.data.body.ztpeGrossWeightTotalAll;
              this.summary.ztpeMetersTotalAll =
                res.data.body.ztpeMetersTotalAll;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "ztpeSettlementQuantity") {
          sums[key] = {
            key,
            value: this.summary.ztpeSettlementQuantityAll
          };
        } else if (key === "ztpeTotalNum") {
          sums[key] = {
            key,
            value: this.summary.ztpeTotalNumAll
          };
        } else if (key === "ztpeClothQuantity") {
          sums[key] = {
            key,
            value: this.summary.ztpeClothQuantityAll
          };
        } else if (key === "ztpeNetWeightTotal") {
          sums[key] = {
            key,
            value: this.summary.ztpeNetWeightTotalAll
          };
        } else if (key === "ztpeGrossWeightTotal") {
          sums[key] = {
            key,
            value: this.summary.ztpeGrossWeightTotalAll
          };
        } else if (key === "ztpeMetersTotal") {
          sums[key] = {
            key,
            value: this.summary.ztpeMetersTotalAll
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    },
    // 进仓细表
    getTableDataList1() {
      this.detailsTableRealColumns.length = 0;
      this.axios({
        url:
          "/dtsum/zongtong/product/productController/productionEntrySheetDetail",
        method: "get",
        params: {
          ztpeId: this.currentId,
          pageSize: this.detailsListData.pageSize,
          pageNum: this.detailsListData.pageNum
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (this.detailsTableRealColumns.length === 0) {
              this.detailsTableRealColumns.push(this.detailsTableColumns[0]);
              for (let i = 0; i < res.data.body.userFilds.length; i++) {
                for (let j = 0; j < this.detailsTableColumns.length; j++) {
                  if (
                    res.data.body.userFilds[i].field ===
                    this.detailsTableColumns[j].key
                  ) {
                    this.detailsTableRealColumns.push(
                      this.detailsTableColumns[j]
                    );
                  }
                }
              }
              this.detailsTableData =
                res.data.body.productionEntrySheetDetail.list;
              this.detailsListDataTotal =
                res.data.body.productionEntrySheetDetail.total;
              this.detailsListData.pageSize =
                res.data.body.productionEntrySheetDetail.pageSize;
              this.detailsListData.pageNum =
                res.data.body.productionEntrySheetDetail.pageNum;
              this.summaryDetail.ztpeiMeterAll = res.data.body.ztpeiMeterAll;
              this.summaryDetail.ztpeiClothNumAll =
                res.data.body.ztpeiClothNumAll;
              this.summaryDetail.ztpeiNetWeightAll =
                res.data.body.ztpeiNetWeightAll;
              this.summaryDetail.ztpeiNumAll = res.data.body.ztpeiNumAll;
              this.summaryDetail.ztpeiGramAll = res.data.body.ztpeiGramAll;
              this.summaryDetail.ztpeiSettledNumAll =
                res.data.body.ztpeiSettledNumAll;
              this.summaryDetail.ztpeiRoughWeightAll =
                res.data.body.ztpeiRoughWeightAll;
              this.summaryDetail.ztpeiSendNumAll =
                res.data.body.ztpeiSendNumAll;
              this.summaryDetail.ztpeiSendWeightAll =
                res.data.body.ztpeiSendWeightAll;
            }
          }
          this.loadingDetail = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingDetail = false;
        });
    },
    handleSummaryDetail({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "ztpeiMeter") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiMeterAll
          };
        } else if (key === "ztpeiClothNum") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiClothNumAll
          };
        } else if (key === "ztpeiNetWeight") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiNetWeightAll
          };
        } else if (key === "ztpeiNum") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiNumAll
          };
        } else if (key === "ztpeiGram") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiGramAll
          };
        } else if (key === "ztpeiSettledNum") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiSettledNumAll
          };
        } else if (key === "ztpeiRoughWeight") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiRoughWeightAll
          };
        } else if (key === "ztpeiSendNum") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiSendNumAll
          };
        } else if (key === "ztpeiSendWeight") {
          sums[key] = {
            key,
            value: this.summaryDetail.ztpeiSendWeightAll
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss" scoped>
#productionEntry {
  .filter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    font-size: $--fontsize-small;
    .filterItem {
      display: inline-flex;
      margin-right: 50px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
      i {
        margin-left: 9px;
      }
      &:last-child {
        width: 132px;
        margin-right: 0px;
        button {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
  .custom {
    position: relative;
    width: 100%;
    // min-height: 95px;
    background: #F4F7FE;
    margin-top: -35px;
    margin-bottom: 39px;
    padding: 16px 8px;
    .triangle {
      position: absolute;
      top: -14px;
      right: 60px;
      width: 0px;
      height: 0px;
      border-top: 0px solid transparent;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #f5f5f5;
    }
    .border {
      display: inline-block;
      width: 1px;
      height: 14px;
      background: #dcdada;
      margin-right: 20px;
      vertical-align: middle;
    }
    .ivu-checkbox-wrapper {
      margin-right: 20px;
    }
    .ivu-checkbox-group {
      display: inline-block;
    }
    .btn {
      margin-top: 17px;
      text-align: right;
    }
  }
  .searchSetting {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
    .settingFilter {
      display: flex;
      flex-wrap: wrap;
      font-size: $--fontsize-small;
      .filterItem {
        display: inline-flex;
        margin-right: 50px;
        margin-bottom: 20px;
        height: 32px;
        line-height: 32px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .settingBtn {
      display: flex;
      line-height: 32px;
      .border {
        width: 1px;
        height: 14px;
        margin-right: 14px;
        background: #dcdada;
        margin-top: 9px;
      }
      .changebtn {
        color: #256de6;
        cursor: pointer;
      }
    }
  }
  .button {
    font-size: $--fontsize-small;
    margin-bottom: 20px;
    margin-top: -20px;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
        .item {
          display: inline-block;
          margin-right: 30px;
          color: $--color-blue-standard;
          cursor: pointer;
          &:nth-child(3) {
            margin-right: 20px;
          }
          &:nth-child(4) {
            width: 1px;
            height: 14px;
            background: #dcdada;
            margin-right: 20px;
            vertical-align: middle;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:130px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    font-size: $--fontsize-small;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
<style lang="scss">
#productionEntry {
  .el-drawer {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .el-drawer__header {
      margin-bottom: 12px;
      padding: 20px 30px 0px 30px;
      font-size: 16px;
      color: #212b36;
    }
    .el-drawer__body {
      overflow: auto;
      padding: 0px 30px 30px 30px;
      .button {
        margin-top: 0px;
        margin-bottom: 17px;
      }
    }
  }
}
</style>
